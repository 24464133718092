var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal-padrao',{ref:"modal",attrs:{"max-width":"900px","titulo":_vm.$t(
      'modulos.verificacao_intermediaria.valores.titulos.confirmar_instrumento_padrao'
    ),"ok-desabilitado":_vm.okDesabilitado,"tooltip":_vm.tooltip},on:{"ok":_vm.alterarInstrumento}},[_c('div',[_c('accordion-padrao',{attrs:{"nao-retrair":"","titulo":_vm.$t('modulos.verificacao_intermediaria.valores.modal.pontos')}},[_c('input-checkbox',{attrs:{"options":_vm.opcoesPontos,"enter-class":"d-flex"},model:{value:(_vm.form.pontos),callback:function ($$v) {_vm.$set(_vm.form, "pontos", $$v)},expression:"form.pontos"}})],1),(_vm.form.pontos.length > 0)?[_c('accordion-padrao',{attrs:{"nao-retrair":"","titulo":_vm.$t(
            'modulos.verificacao_intermediaria.valores.modal.instrumento_atual'
          )}},[(_vm.instrumentosAtuais(true).length == 1)?_c('div',{staticClass:"primary-grey-50 pa-3"},[_c('span',{staticClass:"w600"},[_vm._v(_vm._s(_vm.instrumentosAtuais(true)[0].instrumentoPadrao.nome))])]):_vm._e(),(_vm.instrumentosAtuais(true).length > 1)?_c('div',{staticClass:"primary-grey-50 pa-3 d-flex align-center justify-space-between w600"},[_vm._v(" "+_vm._s(_vm.$t( 'modulos.verificacao_intermediaria.valores.modal.mais_um_instrumento' ))+" ")]):_vm._e()]),_c('accordion-padrao',{attrs:{"nao-retrair":"","titulo":_vm.$t(
            'modulos.verificacao_intermediaria.valores.modal.novo_instrumento'
          )}},[_c('div',{staticClass:"d-flex align-end mb-3"},[_c('input-text',{staticClass:"flex-fill mr-3",attrs:{"label":_vm.$t(
                'modulos.verificacao_intermediaria.valores.modal.tabela.codigo'
              )},model:{value:(_vm.filtro.codigoTag),callback:function ($$v) {_vm.$set(_vm.filtro, "codigoTag", $$v)},expression:"filtro.codigoTag"}}),_c('input-text',{staticClass:"flex-fill mr-3",attrs:{"label":_vm.$t(
                'modulos.verificacao_intermediaria.valores.modal.tabela.tipo_instrumento'
              )},model:{value:(_vm.filtro.nomeTipoInstrumento),callback:function ($$v) {_vm.$set(_vm.filtro, "nomeTipoInstrumento", $$v)},expression:"filtro.nomeTipoInstrumento"}}),_c('input-text',{staticClass:"flex-fill mr-3",attrs:{"label":_vm.$t(
                'modulos.verificacao_intermediaria.valores.modal.tabela.setor'
              )},model:{value:(_vm.filtro.nomeSetor),callback:function ($$v) {_vm.$set(_vm.filtro, "nomeSetor", $$v)},expression:"filtro.nomeSetor"}}),_c('botao-padrao',{attrs:{"outlined":"","color":"secondary"},on:{"click":_vm.filtrar}},[_vm._v(" "+_vm._s(_vm.$t('geral.botoes.filtrar'))+" ")])],1),_c('tabela-padrao',{attrs:{"colunas":_vm.tabela.colunas,"dados":_vm.tabela.dados,"por-pagina":_vm.tabela.porPagina,"quantidade-paginas":_vm.tabela.quantidadePaginas,"selecionar-apenas-um":""},on:{"paginar":_vm.listarRegistros},model:{value:(_vm.tabela.selecionados),callback:function ($$v) {_vm.$set(_vm.tabela, "selecionados", $$v)},expression:"tabela.selecionados"}})],1)]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }