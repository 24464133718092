<template>
  <div>
    <div class="d-flex align-end justify-space-between mb-8">
      <div>
        <span class="titulo-pagina">
          {{ tituloFormulario }}
        </span>
        <div>
          <v-chip v-if="form.id && form.codigo" class="mr-1" small>
            {{ $t('modulos.verificacao_intermediaria.formulario.codigo') }}: {{ `${form.codigo}` }}
          </v-chip>
          <v-chip v-if="form.id && instrumento" class="mx-1" small>
            Instrumento: {{ `${instrumento.codigoTag}` }}
          </v-chip>
          <v-chip v-if="form.id && instrumento?.tipoInstrumento" class="mx-1" small>
            Tipo Instrumento: {{ `${instrumento.tipoInstrumento.nome}` }}
          </v-chip>
        </div>
      </div>
      <input-select v-if="indiceAba == 1" v-model="faixaSelecionada" class="w-35"
        :label="$t('modulos.verificacao_intermediaria.formulario.abas.faixas')"
        :placeholder="$t('geral.inputs.selecione')" :options="faixas" em-filtro :clearable="false"
        @change="salvarAoMudarFaixa" />
    </div>
    <v-tabs v-model="indiceAba" class="abas-padrao" background-color="primary" color="primary" @change="salvarAoMudar">
      <v-tab>
        {{ $t('modulos.verificacao_intermediaria.formulario.abas.dados') }}
      </v-tab>
      <v-tab>
        {{ $t('modulos.verificacao_intermediaria.formulario.abas.valores') }}
      </v-tab>
      <v-tab>
        {{
            $t(
              'modulos.verificacao_intermediaria.formulario.abas.campos_adicionais'
            )
          }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="indiceAba">
      <v-tab-item eager>
        <dados ref="dados" :form="form" @confirmacao-finalizar="confirmarFinalizar" @atualizar="buscarVerificacao"
          @aprovar="aprovar" @reprovar="reprovar" />
      </v-tab-item>
      <v-tab-item eager>
        <valores ref="valores" :form="form" :faixa-selecionada="faixaSelecionada"
          @confirmacao-finalizar="confirmarFinalizar" @atualizar="buscarVerificacao" @aprovar="aprovar"
          @reprovar="reprovar" />
      </v-tab-item>
      <v-tab-item eager>
        <campos-adicionais ref="camposAdicionais" :form="form" @confirmacao-finalizar="confirmarFinalizar"
          @atualizar="buscarVerificacao" @aprovar="aprovar" @reprovar="reprovar" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import {
  VerificacaoIntermediariaService,
  InstrumentoService,
  SetorService,
} from '@common/services';
import { VerificacaoIntermediariaModel } from '@common/models/cadastros/VerificacaoIntermediariaModel';
import { DropdownModel } from '@common/models/geral/DropdownModel';
import Dados from './components/Dados';
import Valores from './components/Valores';
import CamposAdicionais from './components/CamposAdicionais';
import helpers from '@common/utils/helpers';

export default {
  components: {
    Dados,
    Valores,
    CamposAdicionais,
  },
  props: ['id'],
  data() {
    return {
      indiceAba: 0,
      indiceAbaAtual: 0,
      form: new VerificacaoIntermediariaModel({}),
      faixas: [],
      faixaSelecionada: null,
      watchInstrumento: null,
      instrumento: {},
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id) {
        let titulo = 'editar';
        if (this.$route.name == 'verificacao-intermediaria-visualizar')
          titulo = 'visualizar';
        if (this.$route.name == 'verificacao-intermediaria-validar')
          titulo = 'validar';

        return this.$t(`modulos.verificacao_intermediaria.titulos.${titulo}`);
      }
      return this.$t('modulos.verificacao_intermediaria.titulos.novo');
    },
  },
  watch: {
    tituloFormulario: {
      handler() {
        this.$store.dispatch(
          'Layout/alterarTituloPagina',
          this.tituloFormulario
        );
      },
      immediate: true,
    },
    id: async function () {
      if (this.watchInstrumento != null) this.watchInstrumento();
      if (this.id) await this.buscarVerificacao();
      this.watchInstrumento = this.$watch('form.instrumentoId', () => {
        this.buscarParametros();
      });
    },
  },
  async mounted() {
    if (this.id) await this.buscarVerificacao();
    if (this.id) {
      helpers.redirecionarSemPermissao(
        this,
        'VerificacaoIntermediaria',
        'Editar'
      );
    }
    helpers.redirecionarSemPermissao(
      this,
      'VerificacaoIntermediaria',
      'Inserir'
    );
    if (this.$route.params.instrumentoId) {
      this.form.instrumentoId = new DropdownModel(
        this.$route.params.instrumentoId
      );
      this.form.instrumentoId.value = this.$route.params.instrumentoId;
      this.form.instrumentoId.text = this.$route.params.codigo;
    }
    this.watchInstrumento = this.$watch('form.instrumentoId', () => {
      this.buscarParametros();
    });
  },
  methods: {
    buscarVerificacao: async function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      if (this.watchInstrumento != null) this.watchInstrumento();
      await VerificacaoIntermediariaService.buscar(this.id)
        .then((res) => {
          this.form = new VerificacaoIntermediariaModel({
            id: this.id,
            ...res.data,
          });

          this.confirmarVoltarParaEdicao();

          InstrumentoService.buscar(this.form.instrumentoId.value).then(
            (instrumento) => {
              this.faixas = new DropdownModel(
                instrumento.data.instrumentoFaixa,
                'descricao'
              );
              if (this.faixas.length > 0)
                this.faixaSelecionada = this.faixas[0].value;
              this.instrumento = instrumento.data;
            }
          );

          this.watchInstrumento = this.$watch('form.instrumentoId', () => {
            this.buscarParametros();
          });
        })
        .finally(() => {
          var ctx = this;
          setTimeout(() => {
            ctx.$refs.dados.naoAlterado();
            ctx.$refs.camposAdicionais.naoAlterado();
            ctx.$refs.valores.naoAlterado();
          }, 1000);

          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmarVoltarParaEdicao() {
      if (this.form.situacaoVerificacao != 'NaoValidado' && this.$route.name == 'verificacao-intermediaria-validar') {
        this.$router.push({
          name: 'verificacao-intermediaria',
        });
      }

      if (this.form.situacaoVerificacao != 'EmAndamento' && this.$route.name == 'verificacao-intermediaria-editar') {
        var situacaoAvalicao = helpers.SituacaoAvaliacaoEnum.find((el) => el.value == this.form.situacaoVerificacao)?.text;
        this.confirmar(
          this.$t('geral.titulos.atencao'),
          this.$t('modulos.verificacao_intermediaria.confirmar_voltar_edicao').replace('{0}', situacaoAvalicao)
        ).then(() => {
          this.form.situacaoVerificacao = 'EmAndamento'
          this.$refs.dados.salvar();
        })
          .catch(() => {
            this.$router.push({
              name: 'verificacao-intermediaria',
            });
          });
      }
    },
    confirmarFinalizar: function () {
      this.confirmar(
        this.$t('geral.titulos.atencao'),
        this.$t('modulos.verificacao_intermediaria.confirmar_finalizar')
      ).then(() => {
        this.$refs.dados.salvar(false).then(() => {
          VerificacaoIntermediariaService.finalizar(this.id)
            .then(() => {
              this.toastSucesso(
                this.$t(`modulos.verificacao_intermediaria.finalizar_sucesso`)
              );
              this.redirecionaRota();
            })

        })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    aprovar: function () {
      this.confirmar(
        this.$t('geral.titulos.atencao'),
        this.$t('modulos.verificacao_intermediaria.confirmar_aprovar')
      ).then(() => {
        VerificacaoIntermediariaService.aprovar(this.id)
          .then(() => {
            this.toastSucesso(
              this.$t(`modulos.verificacao_intermediaria.aprovado_sucesso`)
            );
            this.redirecionaRota();
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    reprovar: function () {
      this.confirmar(
        this.$t('geral.titulos.atencao'),
        this.$t('modulos.verificacao_intermediaria.confirmar_reprovar')
      ).then(() => {
        VerificacaoIntermediariaService.reprovar(this.id)
          .then(() => {
            this.toastSucesso(
              this.$t(`modulos.verificacao_intermediaria.reprovado_sucesso`)
            );
            this.redirecionaRota();
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    salvarAoMudarFaixa() {
      if (this.$refs.valores.validar()) {
        this.$refs.valores.salvar(false);
      }
    },
    buscarParametros: function () {
      InstrumentoService.buscar(this.form.instrumentoId.value).then(
        (instrumento) => {
          if (!instrumento.data.setorId) return;
          SetorService.buscar(instrumento.data.setorId).then((res) => {
            this.form.temperaturaInicio = res.data.temperatura;
            this.form.temperaturaMeio = res.data.temperatura;
            this.form.temperaturaFim = res.data.temperatura;

            this.form.umidadeInicio = res.data.umidadeRelativa;
            this.form.umidadeMeio = res.data.umidadeRelativa;
            this.form.umidadeFim = res.data.umidadeRelativa;

            this.form.pressaoInicio = res.data.pressaoAtmosferica;
            this.form.pressaoMeio = res.data.pressaoAtmosferica;
            this.form.pressaoFim = res.data.pressaoAtmosferica;
          });
        }
      );
    },
    redirecionaRota() {
      if (
        this.$route.params.instrumentoId == undefined ||
        this.$route.params.instrumentoId == null
      ) {
        this.$router.push({ name: 'verificacao-intermediaria' });
      } else {
        this.$router.push({
          name: 'verificacao-intermediaria-instrumento',
          params: {
            id: this.$route.params.instrumentoId,
            codigo: this.$route.params.codigo,
          },
        });
      }
    },
    salvarAoMudar() {
      if (this.$route.name == 'verificacao-intermediaria-visualizar' || this.$route.name == 'verificacao-intermediaria-validar') {
        this.indiceAbaAtual = this.indiceAba;
        return;
      }
      let alterado = true;
      switch (this.indiceAbaAtual) {
        case 0:
          alterado = this.$refs.dados.foiAlterado();
          break;
        case 1:
          alterado = this.$refs.valores.foiAlterado();
          break;
        case 2:
          alterado = this.$refs.camposAdicionais.foiAlterado();
          break;
        default:
          alterado = false;
          break;
      }
      if (alterado) {
        let valido = false;
        let funcao = null;
        switch (this.indiceAbaAtual) {
          case 0:
            valido = this.$refs.dados.validar();
            funcao = this.$refs.dados.salvar;
            break;
          case 1:
            valido = this.$refs.valores.validar();
            funcao = this.$refs.valores.salvar;
            break;
          case 2:
            valido = this.$refs.camposAdicionais.validar();
            funcao = this.$refs.camposAdicionais.salvar;
            break;
          default:
            valido = true;
            break;
        }
        if (!valido) {
          this.$nextTick(() => {
            this.indiceAba = this.indiceAbaAtual;
          });
          return;
        }
        if (!funcao) {
          this.indiceAbaAtual = this.indiceAba;
          return;
        }
        funcao(this.indiceAba);
      } else {
        this.indiceAbaAtual = this.indiceAba;
      }
    },
    voltarAba: function () {
      this.indiceAba = this.indiceAbaAtual;
    },
  },
};
</script>
