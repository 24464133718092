<template>
  <v-form
    ref="form"
    :disabled="!podeEditar"
  >
    <tabela-padrao-prime-vue
      ref="tabela"
      class="mt-2"
      :dados="tabela.dados"
      :colunas="tabela.colunas"
      :mostrar-acoes="false"
      :mostrar-seletor="false"
      linha-hover
      sem-acoes
      sem-paginacao
      :por-pagina="-1"
      ajustar-altura-linha
    >
      <!-- eslint-disable vue/valid-v-slot-->
      <template v-slot:nome="{ slotProps }">
        <strong>{{ slotProps.data.nome }}
          <span v-if="slotProps.data.obrigatorio"> * </span>
        </strong>
      </template>
      <template v-slot:valor="{ slotProps }">
        {{ buscarValorInput(slotProps.data).valorInput }}
        <input-condicional-campos-adicionais
          v-if="buscarValorInput(slotProps.data).valorInput"
          v-model="buscarValorInput(slotProps.data).valorInput"
          :tipo-input="slotProps.data.tipoCampo"
          :opcoes="slotProps.data.itens"
          :obrigatorio="slotProps.data.obrigatorio"
        />
      </template>
      <template v-slot:valorPadrao="{ slotProps }">
        <span
          v-if="typeof
            slotProps.data.valorPadrao === 'boolean' ||
            slotProps.data.valorPadrao == 'true' ||
            slotProps.data.valorPadrao == 'false'"
          class="primary-grey-400--text"
        >
          {{ slotProps.data.valorPadrao? $t(`modulos.participantes.formulario.campos_adicionais.true`)
            : $t(`modulos.participantes.formulario.campos_adicionais.false`)
          }}
        </span>
        <span
          v-else
          class="primary-grey-400--text"
        >
          {{ slotProps.data.valorPadrao
            ? formatarValorPadrao(slotProps.data)
            : '' }}
        </span>
      </template>
    </tabela-padrao-prime-vue>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        outlined
        color="secondary"
        class="ml-2"
        @click="cancelar"
      >
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao
        v-if="podeEditar"
        class="ml-2"
        @click="salvar"
      >
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
      <botao-padrao
        v-if="form.id && podeEditar"
        class="ml-2"
        @click="confirmacaoFinalizar"
      >
        {{ $t('geral.botoes.finalizar') }}
      </botao-padrao>
      <template v-if="podeValidar">
        <div class="d-flex align-center">
          <botao-padrao
            class="ml-2"
            @click="aprovar"
          >
            {{ $t('geral.botoes.aprovar') }}
          </botao-padrao>
          <botao-padrao
            class="ml-2"
            outlined
            color="red"
            @click="reprovar"
          >
            {{ $t('geral.botoes.reprovar') }}
          </botao-padrao>
        </div>
      </template>
    </div>
  </v-form>
</template>
<script>
import InputCondicionalCamposAdicionais from './inputs/InputCondicionalCamposAdicionais';
import helpers from '@/common/utils/helpers';
import {
  VerificacaoIntermediariaService,
  CampoAdicionalService,
} from '@common/services';
import _ from 'lodash';

export default {
  components: { InputCondicionalCamposAdicionais },
  props: { form: {} },
  data() {
    return {
      conteudo: [],
      camposAdicionaisValido: false,
      alterado: false,
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'nome',
            text: this.$t('modulos.modelo_instrumento.campos_adicionais.nome'),
            sortable: false,
          },
          {
            value: 'valor',
            text: this.$t(
              'modulos.modelo_instrumento.campos_adicionais.valor_verificacao_intermediarias'
            ),
            sortable: false,
            width: 440,
          },
          {
            value: 'valorPadrao',
            text: this.$t(
              'modulos.modelo_instrumento.campos_adicionais.valor_input'
            ),
            sortable: false,
            width: 380,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  computed: {
    podeEditar: function () {
      return (
        this.form.situacaoVerificacao == 'EmAndamento' &&
        this.$route.name == 'verificacao-intermediaria-editar'
      );
    },
    podeValidar: function () {
      return this.$route.name == 'verificacao-intermediaria-validar';
    },
  },
  watch: {
    'form.camposAdicionais': {
      handler() {
        let conteudoClonado = _.cloneDeep(this.form.camposAdicionais);
        this.alterado = true;
        if (conteudoClonado) {
          this.conteudo = conteudoClonado;
          this.listar();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    listar: function (
       paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina,
    ) {
      if (!this.form.tipoInstrumentoId?.value)
        return;
      let parametros = {
         page: paginaAtual,
        amountPerPage: porPagina,
        entidade: 3,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      CampoAdicionalService.listar(
        parametros,
        `tiposInstrumentosIds=${this.form.tipoInstrumentoId.value}`
      )
        .then((res) => {
          this.tabela.dados = res.data.items;

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas= res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
      })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    buscarValorInput: function (item) {
      let obj = this.conteudo.find((conteudo) => conteudo.id == item.id);
      if (obj === undefined) {
        let valor = item.valorPadrao;

        if (valor && item.tipoCampo == 'ListaMultiplaEscolha') {
          if (valor.includes(',')) {
            valor = valor.split(',');
          } else {
            valor = [valor];
          }
        }

        obj = {
          id: item.id,
          value: item.id,
          text: item.nome,
          valorInput: valor,
        };

        this.conteudo.push(obj);
      }
      obj.tipoCampo = item.tipoCampo;

      return obj;
    },

    formatarValorPadrao: function (item) {
      if (item.tipoCampo == 'Data') {
        return helpers.formatarDataBr(item.valorPadrao);
      }
      return item.valorPadrao;
    },

    salvar: function () {
      this.confirmarSalvar().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        VerificacaoIntermediariaService.salvarCamposAdicionais(
          this.form.id,
          this.conteudo
        )
          .then(() => {
            this.toastSucesso(
              this.$t(`modulos.verificacao_intermediaria.campos_sucesso`)
            );
            this.$emit('atualizar');
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    confirmacaoFinalizar: function () {
      this.$emit('confirmacao-finalizar');
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.redirecionaRota();
      });
    },
    aprovar: function () {
      this.$emit('aprovar');
    },
    reprovar: function () {
      this.$emit('reprovar');
    },
    redirecionaRota() {
      if (this.$route.params.instrumentoId) {
        this.$router.push({ name: 'verificacao-intermediaria' });
      } else {
        this.$router.push({
          name: this.$route.query?.retorno || 'verificacao-intermediaria-instrumento',
          params: { id: this.$route.params.instrumentoId, codigo: this.$route.params.codigo },
        });
      }
    },
    validar: function () {
      return this.$refs.form.validate();
    },
    foiAlterado: function () {
      return this.alterado;
    },
    naoAlterado: function () {
      return this.alterado = false;
    }
  },
};
</script>
