<template>
  <div class="mt-3">
    <v-form ref="form" :disabled="!podeEditar">
      <div class="row">
        <input-instrumento v-model="form.instrumentoId" class="col-12 col-md-6" :label="$t('modulos.verificacao_intermediaria.formulario.instrumento')
      " obrigatorio :multiplos="false" :verificacao-intermediaria="true" somente-ativos
          :disabled="verificacaoInstrumento()" />
        <input-date v-model="form.dataVerificacao" class="col-12 col-md-6"
          :label="$t('modulos.verificacao_intermediaria.formulario.data')" obrigatorio apenas-datas-passadas />
        <input-text v-model="form.observacao" class="col-12"
          :label="$t('modulos.verificacao_intermediaria.formulario.observacao')" :max="5000" />
      </div>
      <accordion-padrao :titulo="$t('modulos.verificacao_intermediaria.titulos.temperatura')">
        <div class="row">
          <input-text v-model="form.temperaturaInicio" class="col-12 col-md-6"
            :label="$t('modulos.verificacao_intermediaria.formulario.inicio_c')" type="number" />
          <input-text v-model="form.temperaturaFim" class="col-12 col-md-6"
            :label="$t('modulos.verificacao_intermediaria.formulario.fim_c')" type="number" />
        </div>
      </accordion-padrao>
      <accordion-padrao :titulo="$t('modulos.verificacao_intermediaria.titulos.umidade')">
        <div class="row">
          <input-text v-model="form.umidadeInicio" class="col-12 col-md-6" :label="$t(
      'modulos.verificacao_intermediaria.formulario.inicio_porcentagem'
    )
      " type="number" />
          <input-text v-model="form.umidadeFim" class="col-12 col-md-6" :label="$t('modulos.verificacao_intermediaria.formulario.fim_porcentagem')
      " type="number" />
        </div>
      </accordion-padrao>
      <accordion-padrao :titulo="$t('modulos.verificacao_intermediaria.titulos.pressao')">
        <div class="row">
          <input-text v-model="form.pressaoInicio" class="col-12 col-md-6"
            :label="$t('modulos.verificacao_intermediaria.formulario.inicio_hpa')" type="number" />
          <input-text v-model="form.pressaoFim" class="col-12 col-md-6"
            :label="$t('modulos.verificacao_intermediaria.formulario.fim_hpa')" type="number" />
        </div>
      </accordion-padrao>
    </v-form>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao outlined color="secondary" class="ml-2" @click="cancelar">
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao v-if="podeEditar" :disabled="!valido" :tooltip="tooltipBotaoSalvar" class="ml-2"
        @click="confirmacaoSalvar">
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
      <botao-padrao v-if="form.id && podeEditar" class="ml-2" @click="confirmacaoFinalizar">
        {{ $t('geral.botoes.finalizar') }}
      </botao-padrao>
      <template v-if="podeValidar">
        <div class="d-flex align-center">
          <botao-padrao class="ml-2" @click="aprovar">
            {{ $t('geral.botoes.aprovar') }}
          </botao-padrao>
          <botao-padrao class="ml-2" outlined color="red" @click="reprovar">
            {{ $t('geral.botoes.reprovar') }}
          </botao-padrao>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { InputInstrumento, } from '@/components/inputs';
import { VerificacaoIntermediariaService } from '@common/services';

export default {
  components: {
    InputInstrumento,
  },
  props: ['form'],
  data() {
    return {
      valido: false,
      alterado: false
    };
  },
  computed: {
    tooltipBotaoSalvar: function () {
      if (this.valido) return '';
      return this.$t('geral.tips.formulario_invalido');
    },
    podeEditar: function () {
      return (
        this.form.situacaoVerificacao == 'EmAndamento' &&
        (this.$route.name == 'verificacao-intermediaria-editar' ||
          this.$route.name == 'verificacao-intermediaria-novo' ||
          this.$route.name == 'verificacao-intermediaria-novo-instrumento')
      );
    },
    podeValidar: function () {
      return this.$route.name == 'verificacao-intermediaria-validar';
    },
  },
  watch: {
    form: {
      handler() {
        let ctx = this;
        setTimeout(function () {
          if (ctx.$refs.form) ctx.valido = ctx.$refs.form.validate();
          ctx.alterado = true;
        }, 500);
      },
      deep: true,
    },
  },
  methods: {
    async confirmacaoSalvar() {
      if (this.form.id) {
        this.confirmarSalvar().then(async () => {
          await this.salvar();
        });
      } else await this.salvar();
    },

    async salvar(terminarCarregamento = true) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      return VerificacaoIntermediariaService.salvar(this.form)
        .then((res) => {
          if (terminarCarregamento) {

            this.toastSucesso(
              this.$t(`modulos.verificacao_intermediaria.cadastro_sucesso`)
            );
            if (!this.form.id) {
              this.$router.push({
                name: 'verificacao-intermediaria-editar',
                params: { id: res.data },
              });
              return;
            }

            this.$emit('atualizar');
          }
        })
        .finally(() => {
          if (terminarCarregamento)
            this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    verificacaoInstrumento() {
      if (this.$route.params.instrumentoId) return true;
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.redirecionaRota();
      });
    },
    confirmacaoFinalizar: function () {
      this.$emit('confirmacao-finalizar');
    },
    aprovar: function () {
      this.$emit('aprovar');
    },
    reprovar: function () {
      this.$emit('reprovar');
    },
    redirecionaRota() {
      if (!this.verificacaoInstrumento()) {
        this.$router.push({ name: this.$route.query?.retorno || 'verificacao-intermediaria' });
      } else {
        this.$router.push({
          name: this.$route.query?.retorno || 'verificacao-intermediaria-instrumento',
          params: {
            id: this.$route.params.instrumentoId,
            codigo: this.$route.params.codigo,
          },
        });
      }
    },
    validar: function () {
      return this.$refs.form.validate();
    },
    foiAlterado: function () {
      return this.alterado;
    },
    naoAlterado: function () {
      return this.alterado = false;
    }
  },
};
</script>
