<template>
  <input-text
    v-if="tipoInput === 'Numerico'"
    v-model="conteudo"
    type="number"
    :placeholder="$t('modulos.modelo_instrumento.campos_adicionais.input_tipo_campo')"
    class="col-12"
    :outlined="false"
    flat
    solo
    background-color="transparent"
    :obrigatorio="obrigatorio"
  />
  <input-text
    v-else-if="tipoInput === 'Texto'"
    v-model="conteudo"
    class="col-12"
    :placeholder="$t('modulos.modelo_instrumento.campos_adicionais.input_tipo_campo')"
    :outlined="false"
    flat
    solo
    background-color="transparent"
    type="text"
    :obrigatorio="obrigatorio"
  />
  <input-date
    v-else-if="tipoInput === 'Data'"
    v-model="conteudo"
    class="col-12"
    :obrigatorio="obrigatorio"
  />
  <input-text
    v-else-if="tipoInput === 'Angulo'"
    v-model="conteudo"
    v-mask="mascaraAngulo"
    class="col-12"
    :placeholder="$t('modulos.modelo_instrumento.campos_adicionais.input_tipo_campo')"
    :outlined="false"
    flat
    solo
    background-color="transparent"
    type="text"
    :obrigatorio="obrigatorio"
  />
  <input-select
    v-else-if="tipoInput === 'Booleano'"
    v-model="conteudo"
    :options="opcoesBooleano"
    class="col-12"
    :obrigatorio="obrigatorio"
  />
  <input-combobox-text
    v-else-if="tipoInput === 'ListaEscolhaUnica'"
    v-model="conteudo"
    class="col-12"
    somente-combo-box
    chips
    :options="opcoes"
    :placeholder="$t('geral.inputs.selecione')"
    :multiplo="false"
    :append-icon="'$downArrow'"
    :obrigatorio="obrigatorio"
  />

  <input-combobox-text
    v-else-if="tipoInput === 'ListaMultiplaEscolha'"
    v-model="conteudo"
    class="col-12"
    multiplo
    somente-combo-box
    chips
    :options="opcoes"
    :placeholder="$t('geral.inputs.selecione')"
    :append-icon="'$downArrow'"
    :obrigatorio="obrigatorio"
  />
  <input-text
    v-else
    v-model="conteudo"
    class="col-12"
    type="number"
    :obrigatorio="obrigatorio"
  />
</template>
<script>
import helpers from '@/common/utils/helpers';
export default {
  name: "InputCondicionalModal",
  props: {
    value : {},
    tipoInput: { type: String, default: "Numerico" },
    opcoes : {default : () => []},
    obrigatorio: {type: Boolean,default: false}
  },
  data() {
    return {
      conteudo: "",
      opcoesBooleano: [
        {
          text: this.$t('enums.boleano.sim'),
          value: 'true',
        },
        {
          text: this.$t('enums.boleano.nao'),
          value: 'false',
        },
      ]
    };
  },
    computed:{
    mascaraAngulo(){
      return helpers.removerMascara(this.conteudo).length > 6 ? `###°##'##"` : `##°##'##"`
    }
  },
  watch : {
    value : {
      handler(){
        this.conteudo = this.value;
      },
      immediate : true
    },
    conteudo : function(){
      this.$emit('input', this.conteudo);
    }
  }
};
</script>
