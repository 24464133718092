<template>
  <div>
    <div class="mt-6 d-flex align-center justify-space-between">
      <div @click="editarInstrumentoPadrao">
        <input-text
          :value="instrumentoPadrao"
          readonly
        >
          <template #prepend-inner>
            <div class="mt-1 primary-grey-600--text">
              {{
                $t(
                  'modulos.verificacao_intermediaria.valores.instrumento_padrao'
                )
              }}:
            </div>
          </template>
        </input-text>
      </div>
      <div
        v-if="podeEditar"
        class="d-flex align-center"
      >
        <dropdown-padrao
          :tooltip="
            $t(
              'modulos.verificacao_intermediaria.valores.botoes.tooltip.weight'
            )
          "
          seta
          color="secondary"
          outlined
        >
          <template #botao>
            <v-icon>$weight</v-icon>
          </template>
          <dropdown-padrao-item @click="editarInstrumentoPadrao">
            {{ $t('modulos.verificacao_intermediaria.valores.botoes.editar') }}
          </dropdown-padrao-item>
          <dropdown-padrao-item @click="limparInstrumentoPadrao">
            {{ $t('modulos.verificacao_intermediaria.valores.botoes.limpar') }}
          </dropdown-padrao-item>
        </dropdown-padrao>
        <botao-padrao
          class="ml-2"
          color="secondary"
          :tooltip="
            $t(
              'modulos.verificacao_intermediaria.valores.botoes.tooltip.conversor_unidades'
            )
          "
          outlined
          @click="abrirConversorDeUnidadeDeMedida"
        >
          <v-icon> $calculatorVariantOutline </v-icon>
        </botao-padrao>
      </div>
    </div>
    <v-form ref="form">
      <v-simple-table
        dense
        class="mt-3 tabela-dinamica-calibracao"
      >
        <thead>
          <tr>
            <th scope="rol">
              {{ $t('modulos.verificacao_intermediaria.valores.ponto') }}
            </th>
            <th
              v-for="ciclo in ciclos"
              :key="`th-${ciclo}`"
              scope="rol"
            >
              {{ $t(`modulos.verificacao_intermediaria.valores.${ciclo}`) }}
            </th>
            <th scope="rol">
              {{ $t('modulos.verificacao_intermediaria.valores.media') }}
            </th>
            <th scope="rol">
              {{ $t('modulos.verificacao_intermediaria.valores.erro') }}
            </th>
            <th scope="rol">
              {{ $t('modulos.verificacao_intermediaria.valores.criterio') }}
            </th>
            <th scope="rol">
              {{ $t('modulos.verificacao_intermediaria.valores.situacao') }}
            </th>
            <th scope="rol">
              {{ $t('modulos.verificacao_intermediaria.valores.descricao') }}
            </th>
          </tr>
        </thead>

        <tbody class="linhas">
          <tr
            v-for="linha in valores"
            :key="linha.linhaId"
          >
            <td>
              {{ linha.pontoVerificacao | formatarNumeroComPrecisao }}
            </td>
            <td
              v-for="ciclo in ciclos"
              :key="`td-${ciclo}`"
            >
              <input-text
                v-model="linha[ciclo]"
                type="number"
                :numero-maximo="99999999999999"
                sem-label
                flat
                solo
                background-color="transparent"
                class="flex-fill input-tabela mt-2"
                selecionar-texto-on-focus
                :readonly="!podeEditar"
                @focus="mostrarInstrumentoPadrao(linha.instrumentoPadrao)"
                @input="calcularLinhas(linha)"
              />
            </td>
            <td>{{ linha.media | formatarNumeroComPrecisao  }}</td>
            <td>{{ linha.erro | formatarNumeroComPrecisao }}</td>
            <td>
              <input-text
                v-model="linha.criterio"
                type="number"
                :numero-maximo="99999999999999"
                sem-label
                flat
                solo
                background-color="transparent"
                class="flex-fill input-tabela mt-2"
                selecionar-texto-on-focus
                :readonly="!podeEditar"
                @focus="mostrarInstrumentoPadrao(linha.instrumentoPadrao)"
                @input="calcularLinhas(linha)"
              />
            </td>
            <td
              :class="`${retornarSituacao(linha.situacaoAvaliacao).cor}--text`"
            >
              {{ retornarSituacao(linha.situacaoAvaliacao).text }}
            </td>
            <td>
              <input-text
                v-model="linha.descricao"
                sem-label
                flat
                solo
                background-color="transparent"
                class="flex-fill input-tabela mt-2"
                :readonly="!podeEditar"
                :max="100"
                @focus="mostrarInstrumentoPadrao(linha.instrumentoPadrao)"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        outlined
        color="secondary"
        class="ml-2"
        @click="cancelar"
      >
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao
        v-if="podeEditar"
        class="ml-2"
        @click="confirmacaoSalvar"
      >
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
      <botao-padrao
        v-if="form.id && podeEditar"
        class="ml-2"
        @click="confirmacaoFinalizar"
      >
        {{ $t('geral.botoes.finalizar') }}
      </botao-padrao>
      <template v-if="podeValidar">
        <div class="d-flex align-center">
          <botao-padrao
            class="ml-2"
            @click="aprovar"
          >
            {{ $t('geral.botoes.aprovar') }}
          </botao-padrao>
          <botao-padrao
            class="ml-2"
            outlined
            color="red"
            @click="reprovar"
          >
            {{ $t('geral.botoes.reprovar') }}
          </botao-padrao>
        </div>
      </template>
    </div>
    <modal-instrumento-padrao
      ref="modalInstrumento"
      :valores="valores"
    />
  </div>
</template>
<script>
import helpers from '@common/utils/helpers';
import ModalInstrumentoPadrao from './ModalInstrumentoPadrao';
import { VerificacaoIntermediariaService } from '@common/services';

export default {
  components: {
    ModalInstrumentoPadrao,
  },
  props: ['form', 'faixaSelecionada'],
  data() {
    return {
      ciclos: ['primeiroCiclo', 'segundoCiclo', 'terceiroCiclo'],
      instrumentoPadrao: null,
      alterado : false
    };
  },
  computed: {
    valores() {
      return this.form.valores
        .filter((el) => el.faixaId == this.faixaSelecionada)
        .sort((a, b) => a.pontoVerificacao - b.pontoVerificacao);
    },
    podeEditar: function () {
      return (
        this.form.situacaoVerificacao == 'EmAndamento' &&
        this.$route.name == 'verificacao-intermediaria-editar'
      );
    },
    podeValidar: function () {
      return this.$route.name == 'verificacao-intermediaria-validar';
    },
  },
  watch: {
    'valores': {
      handler() {
        this.alterado = true;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    abrirConversorDeUnidadeDeMedida: function () {
      window.open(
        this.$router.resolve({ name: 'conversor-unidade' }).href,
        '_blank'
      );
    },
    editarInstrumentoPadrao: function () {
      if (!this.podeEditar) return;
      this.$refs.modalInstrumento.abrirModal();
    },
    limparInstrumentoPadrao: function () {
      this.confirmar(
        this.$t(
          'modulos.verificacao_intermediaria.valores.titulos.confirmar_limpar_instrumento_padrao'
        ),
        this.$t(
          'modulos.verificacao_intermediaria.valores.alertas.confirmar_limpar_instrumento_padrao'
        )
      ).then(() => {
        this.form.valores.forEach((element) => {
          element.instrumentoPadrao = null;
        });
      });
    },
    retornarSituacao: function (situacao) {
      return helpers.SituacaoAvaliacaoEnum.find((el) => el.value == situacao);
    },
    mostrarInstrumentoPadrao: function (instrumento) {
      if (!instrumento) this.instrumentoPadrao = '';
      else this.instrumentoPadrao = instrumento.nome;
    },
    confirmacaoSalvar: function () {
      if (!this.$refs.form.validate()) return;
      if (this.form.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },

    confirmacaoFinalizar: function () {
      this.$emit('confirmacao-finalizar');
    },

    salvar: function (atualizar = true) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      VerificacaoIntermediariaService.salvarValores(
        this.form.id,
        this.faixaSelecionada,
        this.valores
      )
        .then((res) => {
          this.toastSucesso(
            this.$t(`modulos.verificacao_intermediaria.valores_sucesso`)
          );
          if (!this.form.id)
            this.$router.push({
              name: 'verificacao-intermediaria-editar',
              params: { id: res.data },
            });
          if (atualizar)
            this.$emit('atualizar');
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.redirecionaRota();
      });
    },
    aprovar: function () {
      this.$emit('aprovar');
    },
    reprovar: function () {
      this.$emit('reprovar');
    },
    redirecionaRota() {
      if (this.$route.params.instrumentoId) {
        this.$router.push({ name: 'verificacao-intermediaria' });
      } else {
        this.$router.push({
          name: this.$route.query?.retorno || 'verificacao-intermediaria-instrumento',
          params: {
            id: this.$route.params.instrumentoId,
            codigo: this.$route.params.codigo,
          },
        });
      }
    },
    calcularLinhas: function (linha) {
      if (!linha.primeiroCiclo || !linha.segundoCiclo || !linha.terceiroCiclo)
        return;
      var quantidadeCiclos = 3;
      linha.media =
        (parseFloat(linha.primeiroCiclo) + parseFloat(linha.segundoCiclo) + parseFloat(linha.terceiroCiclo)) /
        quantidadeCiclos;
      var erroArredondado = (parseFloat(linha.media) - parseFloat(linha.pontoVerificacao)).toFixed(14);
      linha.erro = parseFloat(erroArredondado);

      if (!linha.criterio || !linha.erro) {
        linha.situacaoAvaliacao = 'NaoValidado';
        return;
      }

      if (Math.abs(linha.criterio) >= Math.abs(linha.erro)) {
        linha.situacaoAvaliacao = 'Aprovado';
      } else {
        linha.situacaoAvaliacao = 'Reprovado';
      }
    },
    validar: function () {
      return this.$refs.form.validate();
    },
    foiAlterado: function () {
      return this.alterado;
    },
    naoAlterado: function () {
      return this.alterado = false;
    }
  },
};
</script>
<style lang="scss">
.tabela-dinamica-calibracao {
  th {
    background-color: $color-primary-grey-100;
  }
  .coluna-propriedades {
    width: 0;
  }
  .linhas tr:first-child td {
    border-top: 2px solid;
  }
}
</style>
