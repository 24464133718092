<template>
  <modal-padrao
    ref="modal"
    max-width="900px"
    :titulo="
      $t(
        'modulos.verificacao_intermediaria.valores.titulos.confirmar_instrumento_padrao'
      )
    "
    :ok-desabilitado="okDesabilitado"
    :tooltip="tooltip"
    @ok="alterarInstrumento"
  >
    <div>
      <accordion-padrao
        nao-retrair
        :titulo="$t('modulos.verificacao_intermediaria.valores.modal.pontos')"
      >
        <input-checkbox
          v-model="form.pontos"
          :options="opcoesPontos"
          enter-class="d-flex"
        />
      </accordion-padrao>
      <template v-if="form.pontos.length > 0">
        <accordion-padrao
          nao-retrair
          :titulo="
            $t(
              'modulos.verificacao_intermediaria.valores.modal.instrumento_atual'
            )
          "
        >
          <div
            v-if="instrumentosAtuais(true).length == 1"
            class="primary-grey-50 pa-3"
          >
            <span class="w600">{{
              instrumentosAtuais(true)[0].instrumentoPadrao.nome
            }}</span>
          </div>
          <div
            v-if="instrumentosAtuais(true).length > 1"
            class="primary-grey-50 pa-3 d-flex align-center justify-space-between w600"
          >
            {{
              $t(
                'modulos.verificacao_intermediaria.valores.modal.mais_um_instrumento'
              )
            }}
          </div>
        </accordion-padrao>
        <accordion-padrao
          nao-retrair
          :titulo="
            $t(
              'modulos.verificacao_intermediaria.valores.modal.novo_instrumento'
            )
          "
        >
          <div class="d-flex align-end mb-3">
            <input-text
              v-model="filtro.codigoTag"
              :label="
                $t(
                  'modulos.verificacao_intermediaria.valores.modal.tabela.codigo'
                )
              "
              class="flex-fill mr-3"
            />
            <input-text
              v-model="filtro.nomeTipoInstrumento"
              :label="
                $t(
                  'modulos.verificacao_intermediaria.valores.modal.tabela.tipo_instrumento'
                )
              "
              class="flex-fill mr-3"
            />
            <input-text
              v-model="filtro.nomeSetor"
              :label="
                $t(
                  'modulos.verificacao_intermediaria.valores.modal.tabela.setor'
                )
              "
              class="flex-fill mr-3"
            />
            <botao-padrao
              outlined
              color="secondary"
              @click="filtrar"
            >
              {{ $t('geral.botoes.filtrar') }}
            </botao-padrao>
          </div>

          <tabela-padrao
            v-model="tabela.selecionados"
            :colunas="tabela.colunas"
            :dados="tabela.dados"
            :por-pagina="tabela.porPagina"
            :quantidade-paginas="tabela.quantidadePaginas"
            selecionar-apenas-um
            @paginar="listarRegistros"
          />
        </accordion-padrao>
      </template>
    </div>
  </modal-padrao>
</template>
<script>
import InstrumentoService from '@common/services/cadastros/InstrumentoService';
// import FormulaService from '@common/services/cadastros/FormulaService';
// import { DropdownModel } from '@common/models/geral/DropdownModel';
export default {
  props: {
    valores: {},
  },
  data() {
    return {
      form: {
        pontos: [],
      },
      filtro: {
        flagPadrao: true,
      },
      opcoesVariaveis: [10, 20, 30],
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'codigoTag',
            text: this.$t(
              'modulos.verificacao_intermediaria.valores.modal.tabela.codigo'
            ),
            sortable: false,
          },
          {
            value: 'tipoInstrumento.nome',
            text: this.$t(
              'modulos.verificacao_intermediaria.valores.modal.tabela.tipo_instrumento'
            ),
            sortable: false,
          },
          {
            value: 'setor.nome',
            text: this.$t(
              'modulos.verificacao_intermediaria.valores.modal.tabela.setor'
            ),
            sortable: false,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  computed: {
    okDesabilitado: function () {
      return !this.form.pontos.length || this.tabela.selecionados.length == 0;
    },
    tooltip: function () {
      return !this.okDesabilitado
        ? ''
        : this.$t('modulos.verificacao_intermediaria.valores.modal.tooltip');
    },
    opcoesPontos: function () {
      return this.valores.map((el) => {
        return {
          value: el.pontoVerificacao,
          text: `${el.pontoVerificacao}`,
        };
      });
    },
  },
  methods: {
    instrumentosAtuais: function (distinct = false) {
      let instrumentos = this.valores.filter((el) => {
        let query = [];
        query.push(this.form.pontos.includes(el.pontoVerificacao));

        return query.every((x) => x) && query.length;
      });
      if (!distinct) return instrumentos;
      return instrumentos.filter((value, index, array) => {
        if (value.instrumentoPadrao == null) return false;
        return (
          array.indexOf(
            array.find(
              (el) => el.instrumentoPadrao.id == value.instrumentoPadrao.id
            )
          ) === index
        );
      });
    },
    abrirModal: function () {
      this.$refs.modal.abrirModal();
      this.listarRegistros();
      this.tabela.selecionados = [];
      this.form.pontos = [];
    },
    filtrar: function () {
      this.listarRegistros();
    },
    listarRegistros: function (paginaAtual = 1, porPagina = 5) {
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        ...this.filtro,
        flagPadrao: true,
        flagVerificacaoIntermediaria: true,
        flagAtivo: true
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      InstrumentoService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    alterarInstrumento: function () {
      this.confirmarSalvar().then(() => {
        let instrumento = this.tabela.selecionados.map((selecionado) => {
          return {
            id: selecionado.id,
            nome: selecionado.codigoTag,
          };
        })[0];
        this.valores
          .filter((el) => {
            return this.form.pontos.includes(el.pontoVerificacao);
          })
          .forEach((el) => {
            el.instrumentoPadrao = instrumento;
          });
        this.$refs.modal.fecharModal();
      });
    },
  },
};
</script>
