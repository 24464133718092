import _ from 'lodash';
import { DropdownModel } from '../geral/DropdownModel';
import moment from 'moment';
export class VerificacaoIntermediariaModel {
  constructor({
    id,
    codigo,
    instrumento,
    tipoInstrumento,
    dataVerificacao = moment(),
    observacao,
    temperaturaInicio = 0,
    temperaturaFim = 0,
    umidadeInicio = 0,
    umidadeFim = 0,
    pressaoInicio = 0,
    pressaoFim = 0,
    situacaoVerificacao = 'EmAndamento',
    valores = [],
    camposAdicionais = [],
  }) {
    this.id = id;
    this.codigo = codigo;

    this.instrumentoId = instrumento;
    if (instrumento) this.instrumentoId = new DropdownModel(instrumento);
    
    this.tipoInstrumentoId = tipoInstrumento;
    if (tipoInstrumento)
      this.tipoInstrumentoId = new DropdownModel(tipoInstrumento);

    this.dataVerificacao = dataVerificacao;
    this.observacao = observacao;
    this.temperaturaInicio = temperaturaInicio;
    this.temperaturaFim = temperaturaFim;
    this.umidadeInicio = umidadeInicio;
    this.umidadeFim = umidadeFim;
    this.pressaoInicio = pressaoInicio;
    this.pressaoFim = pressaoFim;
    this.situacaoVerificacao = situacaoVerificacao;
    this.valores = valores;
    this.camposAdicionais = camposAdicionais.map((el) => {
      el.value = el.id;
      el.valorInput = el.valor;
      return el;
    });
  }

  get request() {
    let form = _.cloneDeep(this);
    form.instrumentoId = this.instrumentoId.value;
    form.dataVerificacao = new Date(this.dataVerificacao)
      .toISOString()
      .split('T')[0];
    return form;
  }
}
